<template>
  <div class="register">
    <PageSubHeader :pageSubHeader="pageSubHeader" />
    <main>
      <section id="grocery_nonMember">
        <div class="w1300 layout_container">

          <div class="main_title_box mg-b-50">
            <h3 class="main_title txt-bold">積分申請</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <div class="main_description_box mg-b-50">
            <h4 class="main_description_title txt-light_green txt-bold">申請辦法</h4>
            <ul class="number_list mg-b-10">
              <li>
                <p>請於課程活動開辦前一至六個月內提出申請，未依規定期限申請恕不受理。</p>
              </li>
              <li>
                <p>活動日期僅限填寫單一場次課程（同一個活動名稱及地點），如舉辦多場次（不同的活動名稱、日期時間及地點）請每場分開申請。</p>
              </li>
              <li>
                <p>未經本學會線上回覆認定前，請勿自行公告「台灣居家醫療繼續教育積分若干分」，或刊登類似之廣告。</p>
              </li>
              <li>
                <p>請依據本學會繼續教育積分申請辦法之規定檢附活動相關資料，包括「講師資料表與課程摘要」、「課程表」或「簡章」等。</p>
              </li>
              <li>
                <p>完成申請後，請自行至「積分申請查詢」查詢審核進度。</p>
              </li>
              <li>
                <p>申請案件通過審查者，請自行至「積分申請查詢」繳交行政處理費用，1-8小時活動1000元，9-16小時活動2000元，以此類推。</p>
              </li>
              <li>
                <p>為落實衛生福利部專科醫師繼續教育考核機制，請確實完成簽到及簽退機制。</p>
              </li>
              <li>
                <p>請於活動結束後7日內至「積分申請查詢>上傳成果報告」上傳「出席簽名單掃描檔」、「積分登錄檔」及「授課講義」，辦理後續積分登錄事宜，逾期恕不計分。</p>
              </li>
              <li>
                <p>如有其他問題，請聯絡台灣居家醫療醫學會秘書處。</p>
              </li>
            </ul>
            <p>聯絡電話：04-24360305</p>
            <p>電子郵件：loveminwoo15@yahoo.com.tw</p>
          </div>

          <!-- form area -->
          <div class="form_box">
            <form action="" method="post" onsubmit="return validateForm();">

              <!-- <div class="form_description">

                </div> -->

              <div class="form_interactive">
                <label for="apply_unit" class="w50">
                  <p><span class="txt-red txt-regular">*</span>申請單位</p>
                  <input
                    type="text"
                    id="apply_unit"
                    name="apply_unit"
                    value=""
                    placeholder="請輸入申請單位"
                    required
                  >
                </label>
                <label for="name" class="w50">
                  <p><span class="txt-red txt-regular">*</span>聯絡人</p>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value=""
                    placeholder="請輸入真實姓名"
                    required
                  >
                </label>
                <label for="phone" class="w50">
                  <p><span class="txt-red txt-regular">*</span>聯絡電話</p>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="請輸入聯絡電話"
                    value=""
                    required
                  >
                </label>
                <label for="email" class="w50">
                  <p><span class="txt-red txt-regular">*</span>Email</p>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value=""
                    placeholder="請輸入完整Email"
                    required
                  >
                </label>
                <label for="date_from" class="w50">
                  <p>
                    <span class="txt-red txt-regular">*</span>
                    活動時間 <span class="txt-regular">(起)</span>
                  </p>
                  <input
                    class="date"
                    type="text"
                    id="date_from"
                    name="date_from"
                    value=""
                    placeholder="yyyy-mm-dd"
                    required>
                </label>
                <label for="date_to" class="w50">
                  <p>
                    <span class="txt-red txt-regular">*</span>
                    活動時間 <span class="txt-regular">(迄)</span>
                  </p>
                  <input
                    class="date"
                    type="text"
                    id="date_to"
                    name="date_to"
                    value=""
                    placeholder="yyyy-mm-dd"
                    required
                  >
                </label>
                <label for="topic" class="w50">
                  <p><span class="txt-red txt-regular">*</span>主題</p>
                  <input
                    type="text"
                    id="topic"
                    name="topic"
                    value=""
                    placeholder="請輸入主題名稱"
                    required
                  >
                </label>
                <label for="place" class="w50">
                  <p><span class="txt-red txt-regular">*</span>活動地點</p>
                  <input
                    type="text"
                    id="place"
                    name="place"
                    value=""
                    placeholder="請輸入活動地點"
                    required
                  >
                </label>
                <div class="select_container w50">
                  <p><span class="txt-red txt-regular">*</span>時數</p>
                  <select name="hours" id="hours">
                    <!-- <?php for($i=1;$i<25;$i++) {
                        echo "<option value='$i'>$i</option>";
                      } ?> -->
                  </select>
                </div>
                <label for="host_unit" class="w50">
                  <p><span class="txt-red txt-regular">*</span>主辦單位</p>
                  <input
                    type="text"
                    id="host_unit"
                    name="host_unit"
                    value=""
                    placeholder="請輸入主辦單位"
                    required
                  >
                </label>
                <div class="upload_container w50">
                  <p>
                    <span class="txt-red txt-regular">*</span>
                    課程表 <span class="txt-regular"><br>
                    (檔案大小限制：4MB；檔案類型：zip、doc、docx、pdf)</span>
                  </p>
                  <input
                    type="file"
                    id="class_timetable"
                    name="class_timetable"
                    accept=".zip,.pdf,.doc,.docx"
                    required
                  >
                  <label for="class_timetable">
                    <p class="upload_hint">
                      <span data-placeholder="請選擇檔案">請選擇檔案</span>
                    </p>
                    <p class="upload_btn">
                      <span>選擇檔案</span>
                    </p>
                  </label>
                </div>
                <div class="upload_container w50">
                  <p>
                    <span class="txt-red txt-regular">*</span>
                    講師資料 <a href="download/講師資料表課程摘要-範本.doc"
                    class="template_download small" download>範本下載</a> <span class="txt-regular"><br>
                    (檔案大小限制：4MB；檔案類型：zip、doc、docx、pdf)</span>
                  </p>
                  <input type="file" id="host_info" name="host_info" accept=".zip,.pdf,.doc,.docx"
                  required>
                  <label for="host_info">
                    <p class="upload_hint">
                      <span data-placeholder="請選擇檔案">請選擇檔案</span>
                    </p>
                    <p class="upload_btn">
                      <span>選擇檔案</span>
                    </p>
                  </label>
                </div>
                <label for="upload_password" class="w50">
                  <p>
                    <span class="txt-red txt-regular">*</span>
                    自設密碼 <span class="txt-regular">(上傳成果報告用)</span>
                  </p>
                  <input
                    type="password"
                    id="upload_password"
                    name="upload_password"
                    placeholder="請輸入自設密碼"
                    value=""
                    required
                  >
                  <img class="password_eye" src="@/statics/img/icon/remove_red_eye-24px.svg" alt="">
                </label>
              </div>

              <div class="form_submit txt-center">
                <button class="btn">送出</button>
              </div>

            </form>
          </div>

        </div>

      </section>
    </main>
  </div>
</template>

<script>
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterPointSubHeader } from '@/lib/const';

export default {
  name: 'RegisterPointApply',
  data() {
    return {
      ...RegisterPointSubHeader,
    };
  },
  components: {
    PageSubHeader,
  },
};
</script>
